import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const thisSwiper = {
  swiperInstance: null,
  swiperInstances: [],
  init: function() {
    const self = this;
    const swiperElements = document.querySelectorAll('.js-slider');
    swiperElements.forEach((swiperElement) => {
      const slug = swiperElement.getAttribute('data-slider-slug');
      switch (slug) {
        case 'hero':
          self.swiperInstance = new Swiper(swiperElement, {
            modules: [Navigation, Pagination, Autoplay],
            autoplay: {
              delay: 6000,
              disableOnInteraction: false
            },
            loop: true,
            navigation: {
              nextEl: '.swiper-button-custom-next',
              prevEl: '.swiper-button-custom-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            on: {
              slideChange: function () {
                self.updateSlideInfo(this);
              },
              init: function() {
                self.updateSlideInfo(this);
                self.initAutoplayProgress(this);
              },
              autoplayTimeLeft(swiper, time, progress) {
                self.updateAutoplayProgress(progress);
              }
            }
          });
          self.swiperInstances.push(self.swiperInstance);
          break;

        case 'card-carousel':
          const slidesPerView = swiperElement.getAttribute('data-slides-per-view') || 'auto';

          self.swiperInstance = new Swiper(swiperElement, {
            modules: [Navigation],
            slidesPerView: slidesPerView,
            spaceBetween: 30,
            navigation: {
              nextEl: '.swiper-button-custom-next',
              prevEl: '.swiper-button-custom-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            on: {
              init: function () {
                self.adjustNavButtonPositions(this);
                window.addEventListener('resize', () => {
                  self.adjustNavButtonPositions(this);
                });
              },
            }
          });
          self.swiperInstances.push(self.swiperInstance);
          break;

        case 'cover':
          self.swiperInstance = new Swiper(swiperElement, {
            modules: [Navigation],
            slidesPerView: "auto",
            spaceBetween: 30,
            loop: true,
            navigation: {
              nextEl: '.swiper-button-custom-next',
              prevEl: '.swiper-button-custom-prev',
            },
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            on: {
              slideChange: function () {
                self.updateSlideGlobalTitle(this);
              },
              init: function() {
                self.updateSlideGlobalTitle(this);
              }
            }
          });
          self.swiperInstances.push(self.swiperInstance);
          break;

        case 'gallery':
          self.swiperInstance = new Swiper(swiperElement, {
            modules: [Navigation],
            slidesPerView: "auto",
            spaceBetween: 30,
            loop: true,
            navigation: {
              nextEl: '.swiper-button-custom-next',
              prevEl: '.swiper-button-custom-prev',
            },
            on: {
              slideChange: function () {
                self.updateCounter(this);
              },
              init: function() {
                self.updateCounter(this);
              }
            }
          });
          self.swiperInstances.push(self.swiperInstance);
          break;
      }


    });
  },

  updateSlideInfo(swiper) {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const title = activeSlide.querySelector('.js-slider-slide-title').textContent;
    const type = activeSlide.querySelector('.js-slider-slide-type').textContent;
    const author = activeSlide.querySelector('.js-slider-slide-author').textContent;
    const date = activeSlide.querySelector('.js-slider-slide-date').textContent;
    const upcomingTitle = activeSlide.querySelector('.js-slider-slide-upcoming-title').textContent;

    document.querySelectorAll('.js-slider-upcoming-title').forEach((element) => {
        element.textContent = upcomingTitle;
    });
    document.querySelectorAll('.js-slider-current-type').forEach((element) => {
        element.textContent = type;
    });

    if (author) {
      document.querySelectorAll('.js-slider-current-author').forEach((element) => {
        element.style.display = 'flex';
      });
    } else {
      document.querySelectorAll('.js-slider-current-author').forEach((element) => {
        element.style.display = 'none';
      });
    }
    document.querySelectorAll('.js-slider-current-author-name').forEach((element) => {
        element.textContent = author;
    });
    document.querySelectorAll('.js-slider-current-date').forEach((element) => {
        element.textContent = date;
    });
  },

  updateSlideGlobalTitle(swiper) {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const swiperContainer = swiper.el;
    const sliderCompanion = swiperContainer.nextElementSibling.classList.contains('swiper-companion')
      ? swiperContainer.nextElementSibling
      : null;

    if (!sliderCompanion) {
      console.warn('Slider companion element not found');
      return;
    }

    const title = activeSlide.querySelector('.js-slider-slide-title')?.textContent.trim() || '';
    const subtitle = activeSlide.querySelector('.js-slider-slide-subtitle')?.textContent.trim() || '';

    // Update the title
    const titleElement = sliderCompanion.querySelector('.js-slider-title');
    if (titleElement) {
      titleElement.textContent = title;
    }

    // Update the subtitle
    const subtitleElement = sliderCompanion.querySelector('.js-slider-subtitle');
    if (subtitleElement) {
      subtitleElement.innerHTML = subtitle ? `<p>${subtitle}</p>` : '';
    }

    // Update upcoming title if it exists
    const upcomingTitleElement = swiperContainer.querySelector('.js-slider-upcoming-title');
    if (upcomingTitleElement && swiper.slides[swiper.activeIndex + 1]) {
      const nextSlide = swiper.slides[swiper.activeIndex + 1];
      const nextTitle = nextSlide.querySelector('.js-slider-slide-title')?.textContent.trim() || '';
      upcomingTitleElement.textContent = nextTitle;
    }

    console.log('Slide updated:', { title, subtitle });
  },

  initAutoplayProgress(swiper) {
    const nextButton = swiper.navigation.nextEl;
    if (nextButton) {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('viewBox', '0 0 100 100');
      svg.innerHTML = '<circle cx="50" cy="50" r="49.5" stroke="#FFFFFF" stroke-width="1" fill="none" />';
      svg.style.position = 'absolute';
      svg.style.pointerEvents = 'none';
      svg.style.width = '100%';
      svg.style.height = '100%';
      svg.style.left = '0';
      svg.style.top = '0';
      svg.style.transform = 'rotate(-90deg)';
      nextButton.appendChild(svg);

      const circle = svg.querySelector('circle');
      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = '0';  // Start with a full circle
    }
  },

  updateAutoplayProgress(progress) {
    const nextButton = document.querySelector('.swiper-button-custom-next');
    if (nextButton) {
      const circle = nextButton.querySelector('svg circle');
      if (circle) {
        const radius = circle.r.baseVal.value;
        const circumference = radius * 2 * Math.PI;
        const offset = circumference * progress;  // Inverse the movement
        circle.style.strokeDashoffset = offset;
      }
    }
  },

  adjustNavButtonPositions(swiperInstance) {
    const swiperContainer = swiperInstance.el;
    const swiperThumb = swiperContainer.querySelector('.swiper-thumb');
    const nextButton = swiperContainer.querySelector('.swiper-button-custom-next');
    const prevButton = swiperContainer.querySelector('.swiper-button-custom-prev');

    console.log(swiperThumb, nextButton, prevButton);
    if (swiperThumb && nextButton && prevButton) {
      const thumbRect = swiperThumb.getBoundingClientRect();
      const containerRect = swiperContainer.getBoundingClientRect();
      const middlePosition = thumbRect.top - containerRect.top + (thumbRect.height / 2);

      nextButton.style.top = `${middlePosition}px`;
      prevButton.style.top = `${middlePosition}px`;
    }
  },

  updateCounter(swiper) {
    const currentElement = swiper.el.querySelector('.js-slider-current');
    if (currentElement) {
      const realIndex = swiper.realIndex + 1;

      currentElement.textContent = realIndex;
    }
  },
};

export default thisSwiper;